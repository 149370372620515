import React, { FunctionComponent, useState, useEffect } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { Mutation, MutationFn } from "react-apollo";
import i18n from "es2015-i18n-tag";

// Components
import { ErrorAlertPanel, InfoAlertPanel } from "src/components/alertPanel/alertPanel";
import Loader from "src/components/loader/Loader";

// Types
import { PaymentConfirmationMutation, PaymentConfirmationMutationVariables } from "src/typings/interfaceTypes";

// Queries, mutations
import confirmPaymentMutation from "src/mutations/confirmPaymentMutation";

// Services
import { getUrlParams } from "src/services/parseData";
import handleErrors from "src/services/handleErrors";
import { CreateAlert } from "src/services/handleAlerts";
import webviewBridge from "src/services/webview-bridge";

// Constants
import { alertTypes } from "src/constants/alertConstants";

interface PaymentConfirmationMutationFunc
  extends MutationFn<PaymentConfirmationMutation, PaymentConfirmationMutationVariables> {}

interface PaymentConfirmationProps {
  isLoggedIn: boolean;
}

export const PaymentConfirmation: FunctionComponent<PaymentConfirmationProps> = ({ isLoggedIn }) => {
  return (
    <div className="grid-x grid-padding-x align-center">
      <div className="cell medium-10 large-8 ">
        <h1 style={{ height: "5vh" }}>{i18n`Makse kinnitus`}</h1>
        <Mutation<PaymentConfirmationMutation, PaymentConfirmationMutationVariables> mutation={confirmPaymentMutation}>
          {(invoicePaymentConfirmation: PaymentConfirmationMutationFunc) => {
            return (
              <PaymentConfirmationContents
                isLoggedIn={isLoggedIn}
                invoicePaymentConfirmation={invoicePaymentConfirmation}
              />
            );
          }}
        </Mutation>
      </div>
    </div>
  );
};

interface PaymentConfirmationContentsProps {
  isLoggedIn: boolean;
  invoicePaymentConfirmation: PaymentConfirmationMutationFunc;
}

const PaymentConfirmationContents = (props: PaymentConfirmationContentsProps) => {
  const [result, setResult] = useState<boolean | null>(null);
  const [invoicePaymentConfirmationLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { search } = useLocation();
  const params = getUrlParams(search);
  const history = useHistory();

  const { isLoggedIn, invoicePaymentConfirmation } = props;

  useEffect(() => {
    // Make the payment confirmation request
    if (result === null) {
      setLoading(true);
      invoicePaymentConfirmation({
        variables: { token: params.order_reference, paymentReference: params.payment_reference }
      })
        .then(res => {
          if (res && res.data && res.data.confirmPayment) {
            setResult(res.data.confirmPayment);
            setLoading(false);
            if (isLoggedIn) {
              CreateAlert({
                alertMessage: i18n`Makse õnnestus!`,
                alertType: alertTypes.success
              });
            }
          }
        })
        .catch(err => {
          console.error(err);

          if (err.graphQLErrors && err.graphQLErrors.length) {
            setError(handleErrors(err.graphQLErrors[0]));
          } else {
            setError(handleErrors(err));
          }

          setResult(false);
          setLoading(false);
        });
    }
  }, []);

  if (invoicePaymentConfirmationLoading) {
    return <Loader />;
  }

  if (error !== null) {
    return (
      <div
        className="grid-y"
        style={{ justifyContent: "space-between", height: webviewBridge.isNative() ? "92vh" : "45vh" }}
      >
        <div className="cell auto">
          <ErrorAlertPanel header={i18n`Viga`} text={error} />
        </div>
        {webviewBridge.isNative() && (
          <div className="cell small-1">
            <button className={"button large expanded"} onClick={() => history.push("/private/invoices")}>
              {i18n`Tagasi`}
            </button>
          </div>
        )}
      </div>
    );
  }

  if (result === true) {
    if (isLoggedIn) {
      return <Redirect to="/private/invoices" />;
    }
    return (
      <InfoAlertPanel
        header={i18n("publicView")`Makse õnnestus!`}
        text={i18n("publicView")`Täname! Makse õnnestus.`}
      />
    );
  }

  return null;
};
