// Buttons
export const TOGGLE_BUTTONS_DISABLED: string = "TOGGLE_BUTTONS_DISABLED";

// Alerts
export const ADD_ALERT_ITEM: string = "ADD_ALERT_ITEM";
export const REMOVE_ALERT_ITEM: string = "REMOVE_ALERT_ITEM";

// Menus
export const TOGGLE_PRIVATE_MENU_OPEN: string = "TOGGLE_PRIVATE_MENU_OPEN";
export const SWIPE_PRIVATE_MENU_OPEN: string = "SWIPE_PRIVATE_MENU_OPEN";
export const TOGGLE_ALTERNATIVE_TITLE_VISIBLE = "TOGGLE_ALTERNATIVE_TITLE_VISIBLE";
export const SET_ALTERNATIVE_TITLE = "SET_ALTERNATIVE_TITLE";

// Messages
export const ADD_ACTIVE_MESSAGE = "ADD_ACTIVE_MESSAGE";
export const ADD_DELETED_MESSAGE_ID = "ADD_DELETED_MESSAGE_ID";
export const SET_SHOW_ARCHIVED_MESSAGES = "SET_SHOW_ARCHIVED_MESSAGES";
export const ADD_OFFICIALS = "ADD_OFFICIALS";
export const UPDATE_MESSAGES_CONFIG = "UPDATE_MESSAGES_CONFIG";
export const HANDLE_MESSAGE_OPEN = "HANDLE_MESSAGE_OPEN";

// Association
export const SET_ACTIVE_ASSOCIATION = "SET_ACTIVE_ASSOCIATION";
export const SET_ASSOCIATION_HOUSES = "SET_ASSOCIATION_HOUSES";

// User
export const USER_LOGOUT = "USER_LOGOUT";
export const ADD_USER_ID = "ADD_USER_ID";
export const ADD_USER_OBJECTS = "ADD_USER_OBJECTS";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const ADD_USER_PERMISSIONS = "ADD_USER_PERMISSIONS";

// Locale
export const SET_USER_LOCALE = "SET_USER_LOCALE";

// Objects
export const SET_ACTIVE_OBJECT = "SET_ACTIVE_OBJECT";
