import { SET_USER_LOCALE } from "../actions/actionTypes";
import { SetUserLocaleToStore } from "../interfaceTypes";
import { fetchLocal } from "../../services/handleLocalStorage";

interface InitialState {
  locale: string;
}

const initialState: InitialState = {
  locale: fetchLocal("locale", process.env.REACT_APP_DEFAULT_LOCALE) || process.env.REACT_APP_DEFAULT_LOCALE || "en"
};

export default function locale(state = initialState, action: SetUserLocaleToStore) {
  switch (action.type) {
    case SET_USER_LOCALE: {
      return {
        ...state,
        locale: action.payload.state
      };
    }
    default:
      return state;
  }
}
