import React from "react";
import { withCookies, Cookies } from "react-cookie/cjs";

// Component to render third-party code, i.e. Facebook Pixel, Google Tags etc.
interface Props {
  cookies: Cookies;
}
class ThirdPartyElements extends React.Component<Props, {}> {
  public render() {
    const kortoCookieConsentStatus = this.props.cookies.get("cookieconsent_status_v2");
    const kortoProCookieConsentStatus = this.props.cookies.get("cookieconsent_v2");
    const cookiesAllowed = kortoProCookieConsentStatus === "allow" && kortoCookieConsentStatus === "allow";

    return (
      <React.Fragment>
        {this.props.children}
        <script
          dangerouslySetInnerHTML={{
            __html: `
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      fbq('consent',${cookiesAllowed ? "grant" : "revoke"});
      fbq("init", "${process.env.REACT_APP_FACEBOOK_PIXEL_ID}");
      fbq("track", "PageView");
      `
          }}
        />
      </React.Fragment>
    );
  }
}

export default withCookies(ThirdPartyElements);
