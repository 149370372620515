import {
  ADD_ACTIVE_MESSAGE,
  ADD_DELETED_MESSAGE_ID,
  ADD_OFFICIALS,
  SET_SHOW_ARCHIVED_MESSAGES,
  UPDATE_MESSAGES_CONFIG,
  HANDLE_MESSAGE_OPEN
} from "../actions/actionTypes";
import { MessageType } from "src/typings/types";
import { Contact } from "../interfaceTypes";
import { Config } from "src/typings/interfaceTypes";

interface InitialState {
  activeMessageId: number;
  activeMessageType: MessageType;
  deletedMessageId: number;
  officials: Contact[];
  showArchivedMessages: boolean;
  isMessageVisibleOnSmallScreen: boolean;
  config: Config;
}

const initialState: InitialState = {
  activeMessageId: -1,
  activeMessageType: "",
  deletedMessageId: -1,
  officials: [],
  showArchivedMessages: false,
  isMessageVisibleOnSmallScreen: false,
  config: {
    max_filesize_bytes: 0,
    max_filesize_readable: ""
  }
};

export default function messages(state = initialState, action: any) {
  switch (action.type) {
    case ADD_ACTIVE_MESSAGE: {
      return {
        ...state,
        activeMessageId: action.payload.id,
        activeMessageType: action.payload.message_type
      };
    }
    case ADD_DELETED_MESSAGE_ID: {
      return {
        ...state,
        deletedMessageId: action.payload.deletedMessageId
      };
    }
    case ADD_OFFICIALS: {
      return {
        ...state,
        officials: action.payload.officials
      };
    }
    case SET_SHOW_ARCHIVED_MESSAGES: {
      return {
        ...state,
        showArchivedMessages: action.payload.showArchivedMessages
      };
    }
    case UPDATE_MESSAGES_CONFIG: {
      return {
        ...state,
        config: action.payload.config
      };
    }
    case HANDLE_MESSAGE_OPEN: {
      return {
        ...state,
        isMessageVisibleOnSmallScreen: action.payload.isOpen
      };
    }
    default:
      return state;
  }
}
