import { ADD_USER_ID, ADD_USER_OBJECTS, ADD_USER_DATA, ADD_USER_PERMISSIONS } from "../actions/actionTypes";
import { Permission, Property } from "src/typings/interfaceTypes";

interface InitialState {
  id: string;
  name: string;
  email?: string | null;
  terms_accepted?: boolean;
  objects: Property[] | [];
  permissions: Permission;
}

const initialState: InitialState = {
  id: "",
  name: "",
  email: null,
  terms_accepted: undefined,
  objects: [],
  permissions: {}
};

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_USER_ID: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case ADD_USER_DATA: {
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        terms_accepted: action.payload.terms_accepted
      };
    }
    case ADD_USER_OBJECTS: {
      return {
        ...state,
        objects: action.payload.objects
      };
    }
    case ADD_USER_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload.permissions
      };
    }
    default:
      return state;
  }
}
