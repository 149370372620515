import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { storeLocal, fetchLocal, setUserLocale } from "../../services/handleLocalStorage";
import startsWith from "../../services/polyfills/starts-with";
import { withApollo, compose, ApolloProviderProps } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { defaultLocale } from "src/constants/globalConstants";

startsWith();

class InitialLoadController extends React.Component<RouteComponentProps<{}> & ApolloProviderProps<InMemoryCache>> {
  public componentDidMount() {
    const linkParams = this.props.location.search;
    if (fetchLocal("updatePasswordRedirect") === "1") {
      // Authentication handler
      const currentPath = this.props.location.pathname;
      let loginOption = "";
      let reason = "";
      if (currentPath.startsWith("/public/login/id-card")) {
        loginOption = "id-card";
      } else if (currentPath.startsWith("/public/login/bank")) {
        loginOption = "bank";
      }
      if (linkParams.includes("?reason=")) {
        reason = linkParams.split("?reason=")[1];
      }

      this.props.history.push(
        `/private/settings/user/update-password${
          loginOption !== "" ? `/${loginOption}${reason !== "" ? `/?reason=${reason}` : ""}` : ""
        }`
      );
    } else if (linkParams.includes("apartment") || linkParams.includes("association")) {
      // Deep linking from Korto Pro
      if (linkParams.includes("apartment")) {
        storeLocal("currentObjectId", linkParams.split("=")[1]);
        storeLocal("currentObjectType", "APARTMENT");
      } else {
        storeLocal("currentObjectId", "ASSOCIATION");
        storeLocal("currentObjectType", "APARTMENT");
      }
    } else if (linkParams.includes("success")) {
      const locale = fetchLocal("locale") || process.env.REACT_APP_DEFAULT_LOCALE || defaultLocale;
      setUserLocale(locale, this.props.client);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default compose(withRouter, withApollo)(InitialLoadController);
