import { SET_ACTIVE_OBJECT } from "../actions/actionTypes";
import { Property, ObjectType } from "src/typings/interfaceTypes";

interface InitialState {
  activeObjectId: number;
  activeObjectType?: ObjectType;
  activeObject?: Property;
}

interface SetObjectState {
  type: string;
  payload: {
    activeOject: Property;
  };
}

const initialState: InitialState = {
  activeObjectId: -1, // Active apartment or association
  activeObjectType: undefined,
  activeObject: undefined
};

export default function object(state = initialState, action: SetObjectState) {
  switch (action.type) {
    case SET_ACTIVE_OBJECT: {
      return {
        ...state,
        activeObjectId: parseInt(action.payload.activeOject.id, 10),
        activeObjectType: action.payload.activeOject.type,
        activeObject: action.payload.activeOject
      };
    }
    default:
      return state;
  }
}
