import React from "react";
import { detect } from "detect-browser";

interface State {
  browserSupported: boolean;
}

class BrowserSupportController extends React.Component<{}, State> {
  public state: State = {
    browserSupported: true
  };

  public supportedBrowsers = {
    chrome: "68",
    firefox: "60",
    edge: "15",
    safari: "10",
    ie: "11",
    opera: "33"
  };

  public componentDidMount() {
    const browser = detect();
    if (this.isIEBrowser()) {
      this.setState({
        browserSupported: false
      });
    } else {
      if (browser && browser.name && browser.version) {
        if (this.supportedBrowsers[browser.name]) {
          if (parseInt(this.supportedBrowsers[browser.name], 10) > parseInt(browser.version, 10)) {
            this.setState({
              browserSupported: false
            });
          }
        }
      }
    }
  }

  public render() {
    if (!this.state.browserSupported) {
      return (
        <div className="margin-vertical-3 padding-vertical-3 text-center margin-horizontal-1">
          <h2>Sinu veebilehitseja on vananenud!</h2>
          <p className="margin-bottom-2">
            Korto kasutamiseks palun{" "}
            <strong>
              <a href="http://outdatedbrowser.com/et">uuenda oma veebilehitsejat</a>
            </strong>
            .
          </p>
          <hr />
          <h2>Ваш браузер устарел!</h2>
          <p className="margin-bottom-2">
            Пожалуйста,{" "}
            <strong>
              <a href="http://outdatedbrowser.com/ru">обновите ваш браузер</a>
            </strong>
            , чтобы использовать Korto.
          </p>
          <hr />
          <h2>Your browser is outdated!</h2>
          <p className="margin-bottom-2">
            Please{" "}
            <strong>
              <a href="http://outdatedbrowser.com/en">update your browser</a>
            </strong>{" "}
            to use Korto.
          </p>
        </div>
      );
    }
    return this.props.children;
  }

  private isIEBrowser() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > -1) {
      // IE 10 or older => return version number
      return true;
    }

    /* const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    } */

    // other browser
    return false;
  }
}

export default BrowserSupportController;
