import React from "react";
import NotFoundViewTpl from "src/content/public/not-found/NotFoundViewTpl";
import { MutationFn, Mutation } from "react-apollo";
import reportProblemMutation from "src/mutations/reportProblemMutation";
import { AlertStatus, AlertListElement, ErrorObject } from "src/typings/interfaceTypes";
import i18n from "es2015-i18n-tag";
import { alertTypes, success, error } from "src/constants/alertConstants";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Alert from "src/components/alert/Alert";
import { GraphQLError } from "graphql";
import handleErrors from "src/services/handleErrors";
import find from "../../../services/polyfills/find";
import findIndex from "../../../services/polyfills/find-index";

find();
findIndex();

interface Props {
  error?: Error;
  errorCode?: number;
  errorInfo?: ErrorObject;
  queryError?: GraphQLError | ErrorObject;
  userData?: { name: string; id: string };
  handleAlertStatus?: (alertStatus: AlertStatus) => void; // When used from errorBoundaries we must handle alerts here
}

interface ReportProblem {
  reportProblem: boolean;
}

interface ProblemInput {
  name: string;
  id_code: string;
  contact: string;
  description: string;
}

interface ReportProblemVariables {
  problem: ProblemInput;
}

interface State {
  contact: string;
  feedbackSubmitted: boolean;
  description: string;
  redirectBack: boolean;
  errorSent: boolean;
  alertId: number;
  alertList: AlertListElement[];
}

interface ReportProblemMutationFunc extends MutationFn<ReportProblem, ReportProblemVariables> {}

type PropTypes = RouteComponentProps<{}> & Props;

class NotFoundView extends React.Component<PropTypes, State> {
  public state: State = {
    contact: "",
    feedbackSubmitted: false,
    description: "",
    redirectBack: false,
    errorSent: false,
    alertId: 0,
    alertList: []
  };

  public render() {
    // sends back to /private or /public
    if (this.state.redirectBack) {
      this.props.history.push("/");
      window.location.reload();
    }

    let errorCode = 0;

    if (this.props.errorCode) {
      errorCode = this.props.errorCode;
    } else if (this.props.queryError) {
      // @ts-ignore
      errorCode = this.props.queryError.code ? this.props.queryError.code : 0;
    }

    return (
      <Mutation<ReportProblem, ReportProblemVariables> mutation={reportProblemMutation}>
        {reportProblem => {
          return (
            <NotFoundViewTpl
              loc={window.location.href}
              alertList={this.state.alertList}
              feedbackSubmitted={this.state.feedbackSubmitted}
              errorCode={errorCode}
              reportProblem={() => this.reportProblem(reportProblem)}
              handleContact={this.handleContact}
              handleDescription={this.handleDescription}
              contact={this.state.contact}
              description={this.state.description}
              handleRedirect={this.handleRedirect}
              error={this.props.error}
              errorInfo={this.props.errorInfo}
            />
          );
        }}
      </Mutation>
    );
  }

  private handleContact = (contact: string) => {
    this.setState({
      contact
    });
  };

  private handleDescription = (description: string) => {
    this.setState({
      description
    });
  };

  private handleRedirect = () => {
    this.setState({
      redirectBack: true
    });
  };

  private reportProblem = async (reportProblem: ReportProblemMutationFunc) => {
    try {
      const response = await reportProblem({
        variables: {
          problem: {
            contact: this.state.contact,
            name: this.props.userData ? this.props.userData.name : "",
            description: this.state.description,
            id_code: this.props.userData ? this.props.userData.id : ""
          }
        }
      });
      if (response) {
        const alertStatus = {
          alertMessage: i18n`Veateade edastatud!`,
          alertType: alertTypes.success,
          show: true
        };
        this.props.handleAlertStatus ? this.props.handleAlertStatus(alertStatus) : this.handleAlertStatus(alertStatus);

        this.setState({
          contact: "",
          description: "",
          feedbackSubmitted: true
        });
      }
    } catch (err) {
      const alertStatus = {
        alertMessage: handleErrors(err.GraphQLError[0]),
        alertType: alertTypes.error,
        show: true
      };
      this.props.handleAlertStatus ? this.props.handleAlertStatus(alertStatus) : this.handleAlertStatus(alertStatus);
    }
  };

  private handleAlertStatus = (alertStatus: AlertStatus) => {
    const alert =
      alertStatus.alertType === alertTypes.success ? (
        <Alert
          alertId={this.state.alertId}
          isTransient={true}
          icon={success.icon}
          autoCloseTime={10000}
          type={alertTypes.success}
          title={alertStatus.alertMessage || i18n`Seaded salvestatud!`}
          onClose={this.handleAlertClose}
        />
      ) : (
        <Alert
          alertId={this.state.alertId}
          icon={error.icon}
          isTransient={true}
          autoCloseTime={5000}
          type={alertTypes.error}
          title={alertStatus.alertMessage || i18n`Midagi läks valesti, proovi palun uuesti!`}
          onClose={this.handleAlertClose}
        />
      );

    const alertList = this.state.alertList;
    alertList.push({ element: alert, id: this.state.alertId });
    this.setState({
      alertList,
      alertId: this.state.alertId + 1
    });
  };

  private handleAlertClose = (alertId: number) => {
    const alertList = this.state.alertList;
    const alertIndex = alertList.findIndex(alert => alert.id === alertId);
    alertList.splice(alertIndex, 1);

    this.setState({
      alertList
    });
  };
}

export default withRouter(NotFoundView);
