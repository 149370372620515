import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NotFoundView from "src/views/public/not-found/NotFoundView";
import { GraphQLError } from "graphql";
import { ErrorObject } from "src/typings/interfaceTypes";
import Rollbar from "rollbar";
import { getRollbarFromContext } from "@rollbar/react";
// import { fetchLocal } from "src/services/handleLocalStorage";

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: any;
}

interface Props {
  queryError?: GraphQLError | ErrorObject;
}

type PropTypes = Props & RouteComponentProps<{}>;

class ErrorBoundary extends React.Component<PropTypes, State> {
  public state: State = {
    hasError: false
  };

  private rollbar: Rollbar | undefined;

  public componentDidMount(): void {
    this.rollbar = getRollbarFromContext(this.context);
  }

  public componentDidCatch(err: Error, errorInfo: any) {
    if (errorInfo && errorInfo.componentStack && process.env.REACT_APP_APP_VERSION_TYPE === "DEV") {
      console.log("Stack: ", errorInfo.componentStack);
    }

    // Display fallback UI
    this.setState({ hasError: true, error: err, errorInfo });

    // TrackJS.addMetadata("currentObjectId", fetchLocal("currentObjectId") || "");
    // TrackJS.addMetadata("currentObjectType", fetchLocal("currentObjectType") || "");
    if (this.rollbar) {
      this.rollbar.error(err, errorInfo);
    }
  }

  public render() {
    if (this.state.hasError || this.props.queryError) {
      // You can render any custom fallback UI
      return (
        <NotFoundView
          {...this.props}
          queryError={this.props.queryError ? this.props.queryError : undefined}
          error={this.state.error}
          errorInfo={this.state.errorInfo}
        />
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
