import React from "react";
import Icon from "src/components/icon/Icon";

interface AlertPanelProps {
  header?: string;
  text?: string;
}

export const InfoAlertPanel = ({ header, text }: AlertPanelProps) => (
  <div className="alert-info alert-panel margin-vertical-2">
    <Icon name="alert-info" className="icon" viewBox="432 24 24 24" />
    <div className="alert-content-wrapper">
      {header && <h2>{header}</h2>}
      {text && <p>{text}</p>}
    </div>
  </div>
);

export const AlertPanel = ({ header, text }: AlertPanelProps) => (
  <div className="alert-warning alert-panel margin-vertical-2">
    <Icon name="alert-warning" className="icon" viewBox="455 23 26 25" />
    <div className="alert-content-wrapper">
      {header && <h2>{header}</h2>}
      {text && <p>{text}</p>}
    </div>
  </div>
);

export const ErrorAlertPanel = ({ header, text }: AlertPanelProps) => (
  <div className="alert-error alert-panel">
    <Icon name="alert-error" className="icon" viewBox="408 24 24 24" />
    <div className="alert-content-wrapper">
      {header && <h2>{header}</h2>}
      {text && <p>{text}</p>}
    </div>
  </div>
);
