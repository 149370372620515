import { WebViewBridgeHandler, Payload, Handler } from "./native";

export default class WebViewBridge {
  private isReady: boolean;

  constructor() {
    this.isReady = false;
  }

  public setup = () => {
    this.isReady = true;

    return Promise.resolve({ isReady: this.isReady });
  };

  public registerHandler(handlerName: WebViewBridgeHandler, handler: Handler) {
    /*  */
  }

  public request(handlerName: WebViewBridgeHandler, data: Payload = {}) {
    return Promise.resolve(data);
  }
}
