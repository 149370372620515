import gql from "graphql-tag";

export default gql`
  query GetTranslationInfo {
    translations {
      locales
      translations
      number
    }
  }
`;
