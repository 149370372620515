import React, { useState } from "react";
import webviewBridge from "src/services/webview-bridge";
import { setUserLocale } from "src/services/handleLocalStorage";

interface Props {
  isNative?: boolean;
  children: any;
  handleAppData: (token: string, lang: string) => void;
}

export const EventContext = React.createContext({});
export const PushNotificationPermissionContext = React.createContext({});

function IsFromNative(props: Props) {
  const [eventContext, setEventContext] = useState({ type: "", message: "", lastID: 0, newID: 0 });
  const [pushNotificationPermission, setPushNotificationPermission] = useState(true);

  // Handlers that handle incoming data from native
  webviewBridge.registerHandler("INITIAL_DATA", (data, _cb) => {
    setUserLocale(data.local);
    props.handleAppData(data.token, data.local);
  });

  webviewBridge.registerHandler("UPDATE_MESSAGES", (_data, _cb) => {
    const data = { type: "UPDATE_MESSAGES", message: "" };
    if (window.location.href.includes("messages")) {
      setEventContext(oldEvent => ({ ...oldEvent, ...data, newID: oldEvent.newID + 1 }));
    }
  });

  webviewBridge.registerHandler("NOTIFICATION", (data, _cb) => {
    if (data.type === "URL") {
      window.location.href = data.message;
    } else if (data.type === "INFO_ALERT") {
      setEventContext(oldEvent => ({ ...oldEvent, ...data, newID: oldEvent.newID + 1 }));
    }
  });

  webviewBridge.registerHandler("PUSH_NOTIFICATION_PERMISSION", (data, _cb) => {
    setPushNotificationPermission(data.pushNotificationPermission);
  });

  // webviewBridge.registerHandler("PUSH_NOTIFICATION_PERMISSION", (data, _cb) => {
  //   setPushNotificationPermission(data.pushNotificationPermission);
  // });

  function updateID() {
    // IF new message is received then state object lastID is set to undefined. Here we set it to newID so the logic in children only executes once
    setEventContext(oldEvent => ({ ...oldEvent, lastID: oldEvent.newID }));
  }

  return (
    <EventContext.Provider value={{ ...eventContext, updateID }}>
      <PushNotificationPermissionContext.Provider value={{ permission: pushNotificationPermission }}>
        {props.children}
      </PushNotificationPermissionContext.Provider>
    </EventContext.Provider>
  );
}

export default IsFromNative;
