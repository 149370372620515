const alertTypes = {
  success: "alert-success",
  error: "alert-error",
  warning: "alert-warning",
  info: "alert-info"
};

const success = {
  icon: {
    name: alertTypes.success,
    viewBox: "480 24 24 24"
  }
};

const error = {
  icon: {
    name: alertTypes.error,
    viewBox: "408 24 24 24"
  }
};

const warning = {
  icon: {
    name: alertTypes.warning,
    viewBox: "455 23 24 24"
  }
};

const info = {
  icon: {
    name: alertTypes.info,
    viewBox: "432 24 24 24"
  }
};

export { success, error, warning, info, alertTypes };
