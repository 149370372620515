import React from "react";
import i18n from "es2015-i18n-tag";
import { AlertStatus } from "../typings/interfaceTypes";
import { success, alertTypes, error, info } from "../constants/alertConstants";
import Alert from "../components/alert/Alert";
import Store from "src/store/store";
import { removeAlertElement, addAlertElement } from "../store/actions/";

// Create alert component
export const CreateAlert = (alertStatus: AlertStatus) => {
  const state = Store.getState();
  const alertId = state.alerts.alertId;

  const handleAlertClose = (id: number) => {
    setTimeout(() => Store.dispatch(removeAlertElement(id)), 50);
  };
  try {
    let alert: any;

    if (alertStatus.alertType === alertTypes.info) {
      alert = (
        <Alert
          alertId={alertId}
          isTransient={true}
          icon={info.icon}
          autoCloseTime={7000}
          type={alertTypes.info}
          body={alertStatus.body ? alertStatus.body : undefined}
          title={alertStatus.alertMessage || ""} // App does not send out info alert if message is missing
          onClose={handleAlertClose}
        />
      );
    } else if (alertStatus.alertType === alertTypes.success) {
      // Success alert and Error alert
      alert = (
        <Alert
          alertId={alertId}
          isTransient={true}
          icon={success.icon}
          autoCloseTime={5000}
          type={alertTypes.success}
          body={alertStatus.body ? alertStatus.body : undefined}
          title={alertStatus.alertMessage || i18n`Seaded salvestatud!`}
          onClose={handleAlertClose}
        />
      );
    } else if (alertStatus.alertType === alertTypes.error) {
      alert = (
        <Alert
          alertId={alertId}
          icon={error.icon}
          isTransient={true}
          autoCloseTime={5000}
          type={alertTypes.error}
          body={alertStatus.body ? alertStatus.body : undefined}
          title={alertStatus.alertMessage || i18n`Midagi läks valesti, proovi palun uuesti!`}
          onClose={handleAlertClose}
        />
      );
    }
    // Send alert element to store
    Store.dispatch(addAlertElement(alert));
    return alert;
  } catch (e) {
    console.error("Alert dispatch error: ", e);
  }
};

export const getAlertList = () => {
  const state = Store.getState();

  if (state.alerts.alertList.length > 0) {
    return state.alerts.alertList.map(alert => alert.element.props.title);
  }
  return [];
};
