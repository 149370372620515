import { ADD_ALERT_ITEM, REMOVE_ALERT_ITEM } from "../actions/actionTypes";
import { AlertListElement } from "../../typings/interfaceTypes";
import _ from "lodash";

interface InitialState {
  alertList: AlertListElement[];
  alertId: number;
}

const initialState: InitialState = {
  alertList: [],
  alertId: 0
};

export default function alerts(state = initialState, action: any) {
  switch (action.type) {
    case ADD_ALERT_ITEM: {
      // Check for duplicate values
      if (state.alertList.length > 0) {
        const title = action.payload.alertElement.props.title;
        const alerts = state.alertList.map(alert => alert.element.props.title);
        if (alerts.includes(title)) {
          return state;
        }
      }

      const alertList: AlertListElement[] = _.cloneDeep(state.alertList);
      alertList.push({ element: action.payload.alertElement, id: state.alertId });
      return {
        ...state,
        alertList,
        alertId: state.alertId + 1
      };
    }
    case REMOVE_ALERT_ITEM: {
      const alertList: AlertListElement[] = _.cloneDeep(state.alertList);
      const alertIndex: number = alertList.findIndex(alert => alert.id === action.payload.alertId);
      alertList.splice(alertIndex, 1);

      return {
        ...state,
        alertList
      };
    }
    default:
      return state;
  }
}
