import React from "react";
import i18n from "es2015-i18n-tag";
import Icon from "../../../components/icon/Icon";
import { AlertListElement } from "src/typings/interfaceTypes";

interface Props {
  loc: string;
  contact: string;
  errorCode?: number;
  error?: Error;
  errorInfo?: any;
  description: string;
  feedbackSubmitted: boolean;
  alertList: AlertListElement[];
  reportProblem: () => void;
  handleRedirect: () => void;
  handleContact: (contact: string) => void;
  handleDescription: (description: string) => void;
}

export default ({
  error,
  errorCode,
  errorInfo,
  reportProblem,
  handleContact,
  handleDescription,
  description,
  contact,
  handleRedirect,
  alertList,
  feedbackSubmitted,
  loc
}: Props) => (
  <div className="grid-container fluid">
    {alertList.length !== 0 ? (
      <div className={"alert-container"}>
        {/* tslint:disable-next-line */}
        {alertList.map(alert => (
          <React.Fragment key={alert.id}>{alert.element}</React.Fragment>
        ))}
      </div>
    ) : null}
    <div className="grid-x grid-margin-x align-center">
      <div className="cell medium-8 large-6">
        <Icon
          name="cloud-sad"
          className="icon x-large display-block margin-vertical-1 text-primary-color-dark"
          viewBox="288 24 24 16"
        />
        <h1>
          {i18n`Viga`} {loc}
        </h1>
        {process.env.REACT_APP_APP_VERSION_TYPE === "DEV" && (
          <React.Fragment>
            {errorCode && <p>Code: {errorCode}</p>}
            {error && (
              <div>
                {error.message && (
                  <p>
                    <span style={{ fontWeight: 700 }}>Message: </span>
                    {error.message}
                  </p>
                )}
                {error.stack && (
                  <p>
                    <span style={{ fontWeight: 700 }}>Stack: </span>
                    <code style={{ display: "block", whiteSpace: "pre-wrap" }}>{error.stack}</code>
                  </p>
                )}
              </div>
            )}
            {errorInfo && (
              <div>
                {errorInfo.message && (
                  <p>
                    <span style={{ fontWeight: 700 }}>Message: </span>
                    {errorInfo.message}
                  </p>
                )}
                {errorInfo.source && (
                  <p>
                    <span style={{ fontWeight: 700 }}>Source: </span>
                    {errorInfo.source}
                  </p>
                )}
                {errorInfo.componentStack && (
                  <p>
                    <span style={{ fontWeight: 700 }}>ComponentStack: </span>
                    <code style={{ display: "block", whiteSpace: "pre-wrap" }}>{errorInfo.componentStack}</code>
                  </p>
                )}
              </div>
            )}
          </React.Fragment>
        )}
        <p className="margin-top-3 margin-bottom-2">
          {i18n`Päringu töötlemisel tekkis viga`}
          {errorCode !== 0 ? <strong> ({errorCode})</strong> : null}.{" "}
          {i18n`Palun kirjelda vea asjaolusid, täida kontaktandmed kui vajad abi.`}
        </p>
        {!feedbackSubmitted ? (
          <React.Fragment>
            <label className={"required"}>
              <span className="input-label">{i18n`Kuidas viga tekkis?`}</span>
              <textarea onChange={e => handleDescription(e.target.value)} value={description} rows={3} />
            </label>
            <label>
              {i18n`Telefon või e-post`}
              <input onChange={e => handleContact(e.target.value)} type="text" value={contact} />
            </label>
          </React.Fragment>
        ) : (
          <p>
            <strong>{i18n`Täname tagasiside eest!`}</strong>
          </p>
        )}
        <div className="flex-container align-justify">
          <button className="button hollow" onClick={() => handleRedirect()}>
            ← {i18n`Tagasi`}
          </button>

          {!feedbackSubmitted ? (
            <button
              disabled={description === ""}
              className={`button`}
              onClick={() => reportProblem()}
            >{i18n`Teavita veast`}</button>
          ) : null}
        </div>
      </div>
    </div>
  </div>
);
