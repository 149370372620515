import "intersection-observer";
import "./services/polyfills";
import React, { useState } from "react";
import Loadable from "react-loadable";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import Rollbar from "rollbar";
import { Provider as RollbarProvider } from "@rollbar/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { unregister } from "./registerServiceWorker";
import client from "./services/configure-graphql-client";
import NotFoundView from "./views/public/not-found/NotFoundView";
import TranslationProvider from "./components/translation-provider/TranslationProvider";
import { CookiesProvider } from "react-cookie/cjs";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { ErrorObject, GraphQLError } from "./typings/interfaceTypes";
import DocumentTitleController from "./components/document-title-controller/DocumentTitleController";
import ThirdPartyElements from "./components/third-party-elements/ThirdPartyElements";
import InitialLoadController from "./components/initial-load-controller/InitialLoadController";
import { fetchLocal, removeLocal } from "./services/handleLocalStorage";
import BrowserSupportController from "./components/browser-support-controller/BrowserSupportController";
import IsFromNative from "./components/is-from-native/IsFromNative";
import CodeSplitLoader from "./components/code-split-loader/CodeSplitLoader";
import { Provider } from "react-redux";
import store from "./store/store";
import { PrivateViewProps } from "src/views/private/PrivateView";
import { PaymentConfirmation } from "src/views/public/common/PaymentConfirmation";
import "./services/configureMomentJS";

import webViewBridge from "./services/webview-bridge";
import webviewBridge from "./services/webview-bridge";

import "src/gfx/scss/app.scss";

interface PublicViewProps {
  error: GraphQLError | ErrorObject;
  isMaintenance: boolean;
  disableQueries: boolean;
}

// add webview bridge ready listener for native app
webViewBridge.addBridgeReadyListener();
// init bridge
webViewBridge.init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();

// Component based code splitting
const PrivateViewLoader = Loadable<PrivateViewProps, any>({
  loader: () => import("./views/private/PrivateView" /* webpackChunkName: "PrivateView" */),
  loading: CodeSplitLoader
});

const PublicViewEt = Loadable<PublicViewProps, any>({
  loader: () => import("./views/public/et/PublicView" /* webpackChunkName: "PublicViewEt" */),
  loading: CodeSplitLoader,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

const PublicViewLv = Loadable<PublicViewProps, any>({
  loader: () => import("./views/public/lv/PublicView" /* webpackChunkName: "PublicViewLv" */),
  loading: CodeSplitLoader,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

const rollbarConfig: Rollbar.Configuration = {
  accessToken: "48ebb6180b444b6d9de38e116fa759b4",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: `${process.env.REACT_APP_INSTANCE_COUNTRY}-${process.env.REACT_APP_APP_VERSION_TYPE}`
  }
};

export const rollbar = new Rollbar(rollbarConfig);

function IndexView() {
  const [error, setError] = useState({ message: "", name: "" });
  const [displayErrorAlert, setDisplayErrorAlert] = useState(false);
  const [disableQueries, setDisableQueries] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  const [token, setToken] = useState("");
  const [appSelectedLanguage, setAppSelectedLanguage] = useState<string | null>(null);
  const isNative = webviewBridge.isNative();

  const pageRefreshError = fetchLocal("pageRefreshError");
  if (pageRefreshError) {
    removeLocal("pageRefreshError");
    setDisplayErrorAlert(true);
    setError(JSON.parse(pageRefreshError));
  }

  const PrivateViewWithProps = !disableQueries ? <PrivateViewLoader error={error} /> : null;

  const PublicViewWithProps = () => {
    switch (process.env.REACT_APP_INSTANCE_COUNTRY) {
      case "et":
        return <PublicViewEt disableQueries={disableQueries} isMaintenance={isMaintenance} error={error} />;
      case "lv":
        return <PublicViewLv disableQueries={disableQueries} isMaintenance={isMaintenance} error={error} />;
      default:
        return null;
    }
  };

  function handleOnError(errorObject: {
    error: GraphQLError;
    displayErrorAlert?: boolean;
    disableQueries?: boolean;
    isMaintenance?: boolean;
  }) {
    // Check state values to prevent error loops
    if (errorObject.error.message !== error.message) {
      setError(errorObject.error);
    }
    if (errorObject.displayErrorAlert !== displayErrorAlert) {
      setDisplayErrorAlert(errorObject.displayErrorAlert ? errorObject.displayErrorAlert : false);
    }
    setDisableQueries(errorObject.disableQueries ? errorObject.disableQueries : false);
    setIsMaintenance(errorObject.isMaintenance ? errorObject.isMaintenance : false);
  }

  function handleAppData(appToken: string, lang: string) {
    setToken(appToken);
    setAppSelectedLanguage(lang);
  }

  return (
    <BrowserSupportController>
      <RollbarProvider config={rollbarConfig}>
        <Provider store={store}>
          <CookiesProvider>
            <BrowserRouter>
              <IsFromNative handleAppData={handleAppData} isNative={isNative}>
                <ApolloProvider client={client(handleOnError, rollbar, token)}>
                  <TranslationProvider disableQueries={disableQueries}>
                    <ErrorBoundary queryError={error.name !== "" && displayErrorAlert === false ? error : undefined}>
                      <InitialLoadController>
                        <DocumentTitleController>
                          <ThirdPartyElements>
                            <Switch>
                              {isNative ? null : <Route path="/public" render={() => PublicViewWithProps()} />}
                              {isNative ? (
                                <Route
                                  path={`/public/payment-confirmation`}
                                  exact={true}
                                  render={props => <PaymentConfirmation isLoggedIn={true} {...props} />}
                                />
                              ) : null}
                              <PrivateRoute
                                path="/private"
                                unauthorizedPath="/public"
                                component={PrivateViewWithProps}
                                disableQueries={disableQueries}
                                appLang={appSelectedLanguage}
                                token={token}
                              />
                              <Route
                                path="/"
                                exact={true}
                                component={() => (
                                  <Redirect
                                    to={{
                                      pathname: "/private"
                                    }}
                                  />
                                )}
                              />

                              <Route component={NotFoundView} />
                            </Switch>
                          </ThirdPartyElements>
                        </DocumentTitleController>
                      </InitialLoadController>
                    </ErrorBoundary>
                  </TranslationProvider>
                </ApolloProvider>
              </IsFromNative>
            </BrowserRouter>
          </CookiesProvider>
        </Provider>
      </RollbarProvider>
    </BrowserSupportController>
  );
}

ReactDOM.render(<IndexView />, document.getElementById("root") as HTMLElement);
