import {
  TOGGLE_PRIVATE_MENU_OPEN,
  SWIPE_PRIVATE_MENU_OPEN,
  TOGGLE_ALTERNATIVE_TITLE_VISIBLE,
  SET_ALTERNATIVE_TITLE
} from "../actions/actionTypes";
import { SetPrivateMenuState } from "../interfaceTypes";

interface InitialState {
  isPrivateMenuOpen: boolean;
  privateMenuOpenedBySwipe: boolean;
  isAlternativeTitleVisible: boolean;
  alternativeMenuTitle: string;
}

const initialState: InitialState = {
  isPrivateMenuOpen: false,
  privateMenuOpenedBySwipe: false,
  isAlternativeTitleVisible: false,
  alternativeMenuTitle: ""
};

export default function menus(state = initialState, action: SetPrivateMenuState) {
  switch (action.type) {
    case TOGGLE_PRIVATE_MENU_OPEN: {
      return {
        ...state,
        isPrivateMenuOpen: action.payload.state
      };
    }
    case SWIPE_PRIVATE_MENU_OPEN: {
      return {
        ...state,
        privateMenuOpenedBySwipe: action.payload.state
      };
    }
    case TOGGLE_ALTERNATIVE_TITLE_VISIBLE: {
      return {
        ...state,
        isAlternativeTitleVisible: action.payload.state
      };
    }
    case SET_ALTERNATIVE_TITLE: {
      return {
        ...state,
        alternativeMenuTitle: action.payload.state
      };
    }
    default:
      return state;
  }
}
