import React from "react";
import Icon from "../icon/Icon";
import AlertTpl from "./AlertTpl";

interface Icon {
  name: string;
  viewBox: string;
}

interface Props {
  alertId: number;
  type: string;
  icon: Icon;
  body?: string;
  title: string;
  onClose: (alertId: number) => void;
  isTransient: boolean;
  autoCloseTime?: number;
}

interface State {
  className: string;
}

class Alert extends React.Component<Props, State> {
  public state: State = {
    className: ""
  };

  private timeout: any;
  private isKilled = false;

  public componentDidMount() {
    // close alert after x milliseconds
    if (this.props.autoCloseTime && this.props.onClose !== undefined) {
      this.timeout = setTimeout(this.handleCloseButton, this.props.autoCloseTime);
    }

    this.setState({
      className: "animate-alert-entering"
    });
  }

  public componentWillUnmount() {
    this.isKilled = true;
  }

  public render() {
    const alert = (
      <AlertTpl
        type={this.props.type}
        className={this.state.className}
        icon={this.props.icon}
        isTransient={this.props.isTransient}
        body={this.props.body}
        onClose={this.handleCloseButton}
        title={this.props.title}
      />
    );

    return alert;
  }

  private handleCloseButton = () => {
    // return if the component has been unmounted (logged out etc)
    if (this.isKilled) {
      console.log("Alert component killed");

      return;
    }

    clearTimeout(this.timeout);
    this.setState(
      {
        className: "animate-alert-exiting"
      },
      () => setTimeout(() => this.props.onClose(this.props.alertId), 50)
    );
  };
}

export default Alert;
