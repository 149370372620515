import { createStore } from "redux";
import { rootReducer } from "./reducers";

export interface StoreInterface {
  isButtonsDisabled: boolean;
}

const initialState: any = {
  buttons: {
    isButtonsDisabled: false
  }
};

export default createStore(
  rootReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
