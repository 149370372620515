import { SET_ACTIVE_ASSOCIATION, SET_ASSOCIATION_HOUSES } from "../actions/actionTypes";
import { Association, House } from "src/typings/interfaceTypes";

interface InitialState {
  activeAssociation: Association | null;
  houses: House[] | undefined;
}

interface SetAssociationState {
  type: string;
  payload: {
    state: any;
  };
}

const initialState: InitialState = {
  activeAssociation: null, // Filled only from the "association" menu
  houses: undefined
};

export default function association(state = initialState, action: SetAssociationState) {
  switch (action.type) {
    case SET_ACTIVE_ASSOCIATION: {
      return {
        ...state,
        activeAssociation: action.payload.state
      };
    }
    case SET_ASSOCIATION_HOUSES: {
      return {
        ...state,
        houses: action.payload.state
      };
    }
    default:
      return state;
  }
}
