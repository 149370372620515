import { TOGGLE_BUTTONS_DISABLED } from "../actions/actionTypes";
import { SetButtonsState } from "../interfaceTypes";

interface InitialState {
  isButtonsDisabled: boolean;
}

const initialState: InitialState = {
  isButtonsDisabled: false
};

export default function buttons(state = initialState, action: SetButtonsState) {
  switch (action.type) {
    case TOGGLE_BUTTONS_DISABLED: {
      return {
        ...state,
        isButtonsDisabled: action.payload.state
      };
    }
    default:
      return state;
  }
}
