import React from "react";
import IconSet from "../../gfx/img/iconset-korto.svg";
import IconSetPublic from "../../gfx/img/iconset-korto-public.svg";
import "./Icon.scss";

type Icon =
  // Icons from app iconset
  | "edit"
  | "edit-outline"
  | "logo_full"
  | "logo_symbol"
  | "logo_wordmark"
  | "logo_wordmark_monochrome"
  | "logo-betatag"
  | "message-pinned"
  | "window-minimize"
  | "window-maximize"
  | "readings-archive"
  | "download"
  | "arrow-left"
  | "message"
  | "logout"
  | "alert-error"
  | "alert-warning"
  | "alert-success"
  | "alert-info"
  | "cloud-sad"
  | "settings"
  | "bill"
  | "association"
  | "apartment"
  | "state-id"
  | "smart-id"
  | "dashboard"
  | "attachment"
  | "vote"
  | "vote-agree"
  | "vote-disagree"
  | "beetatag"
  | "wait"
  | "archive-outline"
  // Icons from public iconset
  | "accounting"
  | "association-communication"
  | "alert-success"
  | "document-hosting"
  | "remote-accounting"
  | "readings"
  | "share-permissions"
  | "voting"
  | string;

interface Props {
  name: Icon;
  viewBox: string;
  className?: string;
  publicIcon?: boolean;
}
export default ({ name, viewBox, className = "", publicIcon }: Props) => (
  <svg className={className} viewBox={viewBox}>
    <use xlinkHref={`${publicIcon ? IconSetPublic : IconSet}#${name}`} />
  </svg>
);
