import React from "react";
import Loader from "../loader/Loader";
import i18n from "es2015-i18n-tag";

const codeSplitLoader = (props: any) => {
  if (props.error) {
    console.log("codeSplitLoader error: ", props.error);
    // tslint:disable-next-line:no-unused-expression
    // window.TrackJS && TrackJS.track(props.error);

    return (
      <div className="grid-x grid-padding-x align-center">
        <div className="medium-6 large-5 cell text-center padding-vertical-3">
          <h1>{i18n`Korto on uuenenud!`}</h1>
          <p className="margin-bottom-2">{i18n`Palun värskenda lehekülge.`}</p>
          <button className="button large expanded" onClick={() => window.location.reload()}>
            {i18n`Värskenda`}
          </button>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default codeSplitLoader;
