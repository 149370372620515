import { WebViewBridgeHandler, ResponseData, Payload, NativeHandler } from "./native";
// import { getActiveLocale } from '../locale';

enum PlatformName {
  WEB = "web",
  NATIVE = "native"
}

interface NavigationParams {
  [k: string]: any;
}

interface Bridge {
  setup: () => Promise<{ isReady: boolean }>;
  request: (handlerName: WebViewBridgeHandler, data: {}) => Promise<ResponseData>;
  registerHandler: (handlerName: NativeHandler, handler: (args: any) => void) => void;
}

type LogOutReason = "USER_INTERACTION" | "SESSION_EXPIRED" | "JWT_EXPIRED";

interface DownloadPayload {
  uri: string;
  contentType?: string;
  fileName?: string;
}

const pendingRequests: any = [];
const pendingHandlers: any = [];
let bridge: Bridge;
let isWebViewReady = false;

const isNative = () => {
  if (isWebViewReady) {
    return !!window.WebViewBridge;
  }

  return "ReactNativeWebView" in window || "ReactABI33_0_0NativeWebView" in window;
};

const getNativeVersion = () => {
  let version = "0.0.0";
  const cookies = document.cookie.split(" ");

  cookies.forEach((cookie: string) => {
    const pair = cookie.split("=");

    if (pair[0].trim() === "nativeVersion") {
      version = pair[1];
    }
  });

  return version;
};

const addBridgeReadyListener = () => {
  document.addEventListener("onWebViewReady", async () => {
    if (isWebViewReady) {
      return;
    }

    isWebViewReady = true;

    await setupBridge(getPlatform());

    pendingRequests.forEach((pendingRequest: any) => {
      pendingRequest();
    });

    pendingHandlers.forEach((handler: any) => {
      bridge.registerHandler(handler.handlerName, handler.handler);
    });
  });

  if (!isNative()) {
    document.dispatchEvent(new CustomEvent("onWebViewReady"));
  }
};

const getPlatform = () => {
  if (isNative()) {
    return PlatformName.NATIVE;
  }

  return PlatformName.WEB;
};
const setupBridge = async (platform: PlatformName) => {
  const WebViewBridge = require(`./${platform}`).default;

  bridge = new WebViewBridge();
  return bridge.setup();
};

const platformRequest = (method: WebViewBridgeHandler, args?: Payload): Promise<ResponseData | void> => {
  return new Promise((resolve, reject) => {
    const request = async () => {
      const result = await bridge.request(method, args as any);

      if (result && result.error) {
        reject(result.error);
      } else {
        resolve(result);
      }
    };

    if (bridge) {
      request();
    }
    pendingRequests.push(request);
  });
};

const registerHandler = (handlerName: NativeHandler, handler: (...args: any) => void) => {
  if (!bridge) {
    pendingHandlers.push({ handlerName, handler });
  } else {
    bridge.registerHandler(handlerName, handler);
  }
};

// registerHandler("SEND_TOKEN", (data, cb) => {
//   window.alert(JSON.stringify(data));

//   if (cb) {
//     cb("Done");
//   }
// });

export default {
  addBridgeReadyListener,

  registerHandler,

  isNative,

  getNativeVersion,

  // TODO: add active locale reading
  init: () => platformRequest("INIT", { locale: "et" }),

  // set locale and start loading state
  updateLocale: (locale: string) =>
    platformRequest("UPDATE_LOCALE", {
      locale
    }),

  logout: (reason?: LogOutReason) => platformRequest("LOGOUT", { reason }),

  handleDownload: (payload: DownloadPayload, e?: React.MouseEvent<any>) => {
    if (isNative()) {
      if (e) {
        e.preventDefault();
      }

      return platformRequest("DOWNLOAD_FILE", payload);
    }
    return;
  },

  openLink: (uri: string) => {
    platformRequest("OPEN_LINK", { uri });
  },

  navigate: (to: string, params: NavigationParams) => {
    platformRequest("NAVIGATE", { to, params });
  },

  registerPushToken: () => {
    platformRequest("REGISTER_PUSH_TOKEN");
  },

  log: (message: string, data: any) => {
    platformRequest("LOG", { message, data });
  },

  restartMobileApp: () => {
    platformRequest("RESTART_APP");
  }
};
