import React from "react";
import Icon from "../icon/Icon";
import "./Alert.scss";
interface IconType {
  name: string;
  viewBox: string;
}

interface Props {
  icon: IconType;
  type: string;
  title: string;
  body?: string;
  isTransient: boolean;
  onClose?: () => void;
  className: string;
}

const AlertTpl = ({ body, onClose, title, icon, isTransient, type, className }: Props) => (
  <div className={`alert-panel ${type} ${className}`}>
    <Icon name={icon.name} className="icon" viewBox={icon.viewBox} />
    <div className={`alert-content-wrapper`}>
      <h2>{title}</h2>
      {body ? <p>{body}</p> : null}
      {isTransient && onClose ? (
        <button className="close-button" aria-label="Dismiss alert" type="button" onClick={() => onClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}
    </div>
  </div>
);

export default AlertTpl;
