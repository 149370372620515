import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import i18n from "es2015-i18n-tag";
import { fetchLocal } from "src/services/handleLocalStorage";
import { defaultLocale } from "src/constants/globalConstants";

enum pageUrls {
  messages = "/private/messages",
  root = "/private",
  readings = "/private/readings",
  invoices = "/private/invoices",
  association = "/private/association",
  financials = "/private/association/financials",
  calendar = "/private/association/calendar",
  files = "/private/association/files",
  settings = "/private/settings",
  settingsApartment = "/private/settings/apartment",
  settingsUser = "/private/settings/user",
  addPerson = "/private/settings/apartment/add-person",
  updatePassword = "/private/settings/user/update-password",
  public = "/public",
  introduction = "/public/introduction",
  prices = "/public/prices",
  signup = "/public/signup",
  about = "/public/about",
  news = "/public/news",
  rfp = "/public/request-for-proposal",
  privacy = "/public/privacy"
}

enum localeRuTitle {
  "public" = "Надежное решение для квартирного товарищества! – Korto",
  "introduction" = "Функции – Korto",
  "prices" = "Цены – Korto",
  "signup" = "Создание учетной записи – Korto",
  "about" = "О нас – Korto",
  "news" = "Новости – Korto",
  "help" = "help – Korto",
  "privacy" = "Приватность – Korto",
  "login" = "Войти – Korto"
}

enum localeEtTitle {
  "public" = "Kindel lahendus korteriühistule! – Korto",
  "introduction" = "Tutvustus – Korto",
  "prices" = "Hinnad – Korto",
  "signup" = "Konto loomine – Korto",
  "about" = "Meist – Korto",
  "news" = "Uudised – Korto",
  "privacy" = "Privaatsus – Korto",
  "login" = "Sisenemine – Korto"
}

class DocumentTitleController extends React.Component<RouteComponentProps<{}>> {
  public componentDidMount() {
    this.pageTitle(this.props.history.location.pathname);
    // Does not activate on load. Must call pageTitle on load
    this.props.history.listen(location => {
      this.pageTitle(location.pathname);
    });
  }

  public render() {
    return this.props.children;
  }

  private pageTitle = (url: string) => {
    const locale = fetchLocal("locale") || process.env.REACT_APP_DEFAULT_LOCALE || defaultLocale;
    if (
      url === pageUrls.messages ||
      url === pageUrls.messages + "/" ||
      url === pageUrls.root ||
      url === pageUrls.root + "/"
    ) {
      document.title = `${i18n`Teated`} – Korto`;
    } else if (url === pageUrls.readings || url === pageUrls.readings + "/") {
      document.title = `${i18n`Näidud`} – Korto`;
    } else if (url === pageUrls.invoices || url === pageUrls.invoices + "/") {
      document.title = `${i18n`Arved`} – Korto`;
    } else if (
      url === pageUrls.association ||
      url === pageUrls.association + "/" ||
      url === pageUrls.financials ||
      url === pageUrls.financials + "/" ||
      url === pageUrls.calendar ||
      url === pageUrls.calendar + "/" ||
      url === pageUrls.files ||
      url === pageUrls.files + "/"
    ) {
      document.title = `${i18n`Ühistu`} – Korto`;
    } else if (
      url === pageUrls.settings ||
      url === pageUrls.settings + "/" ||
      url === pageUrls.settingsApartment ||
      url === pageUrls.settingsApartment + "/" ||
      url === pageUrls.settingsUser ||
      url === pageUrls.settingsUser + "/"
    ) {
      document.title = `${i18n`Seaded`} – Korto`;
    } else if (url === pageUrls.addPerson || url === pageUrls.addPerson + "/") {
      document.title = `${i18n`Lisa isik`} – Korto`;
    } else if (url === pageUrls.updatePassword || url === pageUrls.updatePassword + "/") {
      document.title = `${i18n`Parooli muutmine`} – Korto`;
    } else if (url === pageUrls.public || url === pageUrls.public + "/") {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.public;
          return;
        case "ru":
          document.title = localeRuTitle.public;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else if (url === pageUrls.introduction || url === pageUrls.introduction + "/") {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.introduction;
          return;
        case "ru":
          document.title = localeRuTitle.introduction;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else if (url === pageUrls.prices || url === pageUrls.prices + "/") {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.prices;
          return;
        case "ru":
          document.title = localeRuTitle.prices;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else if (url === pageUrls.about || url === pageUrls.about + "/") {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.about;
          return;
        case "ru":
          document.title = localeRuTitle.about;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else if (url === pageUrls.signup || url === pageUrls.signup + "/") {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.signup;
          return;
        case "ru":
          document.title = localeRuTitle.signup;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else if (url === pageUrls.news || url === pageUrls.news + "/") {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.news;
          return;
        case "ru":
          document.title = localeRuTitle.news;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } /* else if (url === pageUrls.rfp || url === pageUrls.rfp + "/") {
      switch(locale) {
        case "et":
          document.title = localeEtTitle.introduction;
        case "ru":
          document.title = localeRuTitle.introduction
      }
      document.title = `${"RFP" /* i18n`Request for proposal` } – Korto`;
    } */ else if (
      url === pageUrls.privacy ||
      url === pageUrls.privacy + "/"
    ) {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.privacy;
          return;
        case "ru":
          document.title = localeRuTitle.privacy;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else if (url.includes("login")) {
      switch (locale) {
        case "et":
          document.title = localeEtTitle.login;
          return;
        case "ru":
          document.title = localeRuTitle.login;
          return;
        default:
          document.title = "Korto";
          return;
      }
    } else {
      document.title = "Korto";
    }
  };
}

export default withRouter(DocumentTitleController);
