import React from "react";
import { compose, ChildProps, withApollo, Query } from "react-apollo";
import { Redirect, Route, RouteProps } from "react-router";
import { InMemoryCache } from "apollo-cache-inmemory/lib/inMemoryCache";
import { ApolloProviderProps } from "react-apollo/ApolloProvider";
import Loader from "../loader/Loader";
import viewerQuery from "../../queries/viewerQuery";
import { setUserLocale, fetchLocal } from "../../services/handleLocalStorage";
import webviewBridge from "src/services/webview-bridge";

interface QueryResponses {
  viewer?: {
    id: string;
    language: string;
  };
}

interface State {
  refetchCount: number;
}

type OwnProps = RouteProps & {
  unauthorizedPath: string;
  component: any;
  disableQueries: boolean;
  appLang?: string;
  token?: string;
};
type Props = ChildProps<OwnProps> & ApolloProviderProps<InMemoryCache>;

class PrivateRoute extends React.Component<Props> {
  public state: State = {
    refetchCount: 0
  };
  public render() {
    if (this.props.disableQueries) {
      return <Redirect to={"/public"} />;
    }

    if (webviewBridge.isNative() && !this.props.token) {
      return <Loader />;
    }

    return (
      <Query<QueryResponses> query={viewerQuery} fetchPolicy="network-only">
        {({ loading, data, error, refetch }) => {
          if (loading) {
            return <Loader />;
          }

          // get users selected language
          if (data && data.viewer && !error) {
            const currentLocale = fetchLocal("locale", process.env.REACT_APP_DEFAULT_LOCALE);
            if (currentLocale !== data.viewer.language && !this.props.appLang) {
              setUserLocale(data.viewer.language, this.props.client);
            }
          }
          const { component: Component, unauthorizedPath, ...rest } = this.props;

          // Check if is from webview and if query failed send logout signal
          if (webviewBridge.isNative()) {
            // Constructed this logic the same way routing logic below to mach same cases.
            if (data && data.viewer && !error) {
              // Pass
              webviewBridge.log("viewerQuery login data:", data);
            } else if (error) {
              webviewBridge.log("viewerQuery login error", error);
              webviewBridge.logout("JWT_EXPIRED");
            } else if (!data) {
              webviewBridge.log("!data", data);
              return <Loader />;
            } else if (!data.viewer && this.state.refetchCount <= 10) {
              webviewBridge.log("!data.viewer", data);
              webviewBridge.log("refetch count", this.state.refetchCount);
              // For iOS bug. If no viewer data is returned refetch the data (max 10 times)
              this.setState({ refetchCount: this.state.refetchCount + 1 });
              refetch();
              return <Loader />;
            } else {
              webviewBridge.log("viewerQuery login failed. Data: ", data);
              webviewBridge.log("viewerQuery login failed. Error: ", error);
              webviewBridge.logout("JWT_EXPIRED");
            }
          }
          return (
            <Route
              {...rest}
              render={props =>
                data && data.viewer && !error ? (
                  Component
                ) : (
                  <Redirect
                    to={{
                      pathname: unauthorizedPath,
                      state: { from: props.location }
                    }}
                  />
                )
              }
            />
          );
        }}
      </Query>
    );
  }
}

export default compose(withApollo)(PrivateRoute);
