import gql from "graphql-tag";

export default gql`
  query GetViewerInfo {
    viewer {
      id
      name
      language
      has_password
      is_demo
      last_login
      email
    }
  }
`;
