import moment from "moment";

import { Reserves, ReservesEntries } from "../typings/interfaceTypes";

export interface UrlParamsReturn {
  [index: string]: string;
}

export const getUrlParams = (url: string): UrlParamsReturn => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};

  if (!url) {
    return params;
  }

  while (true) {
    const result = regex.exec(url);
    if (result) {
      params[result[1]] = result[2];
    } else {
      break;
    }
  }

  return params;
};

interface GroupedReserves {
  name: string;
  balance: string;
  entries: Entry[][];
}

interface Entry extends ReservesEntries {
  month?: number;
  year?: number;
}

export const groupEntriesByMonth = (data: Reserves[]) => {
  try {
    /**
     * @Input
     * [
     *  {
     *    name: "some account name",
     *    entries: [
     *      {
     *        date: '01.01.2000',
     *        ...etc
     *      }
     *    ]
     *  }
     * ]
     * @Returns
     * [
     *  { name: "some account name",
     *    entries: [
     *            [{
     *              month: month 1,
     *              year: 2000
     *              ...etc.
     *             }],    // month 1
     *            [{
     *              month: month 2,
     *              year: 2000
     *              ...etc.
     *             }]     // month 2
     *            ],
     *    ...etc
     *  },
     *  {  name: "some other account",
     *    entries:[
     *              [{
     *                month: month 1,
     *                year: 2000
     *                ...etc.
     *              }],    // month 1
     *              [{
     *                month: month 2,
     *                year: 2000
     *                ...etc.
     *              }]     // month 2
     *              ],
     *            ]
     *  }
     * ]
     */

    // Group by accounts
    const groupedList: GroupedReserves[] | any[] = [];

    // Group by months
    const groupByMonth = (entries: Entry[]): Entry[] => {
      const res = entries.reduce((list, row) => {
        const month = moment(row.date, "DD.MM.YYYY").month();
        row.month = month;
        row.year = moment(row.date, "DD.MM.YYYY").year();

        if (!list[month]) {
          list[month] = [row];
        } else {
          list[month].push(row);
        }

        return list;
      }, {});

      const keys = (Object.keys(res) as Array<keyof typeof res>).sort((a: number, b: number) => b - a);
      // console.log("keys:", keys);
      return keys.map(month => res[month]);
    };

    data.forEach(account => {
      if (account.entries && Array.isArray(account.entries)) {
        const res = {
          ...account,
          entries: groupByMonth(account.entries)
        };
        // @ts-ignore
        groupedList.push(res);
      } else {
        // @ts-ignore
        groupedList.push(account);
      }
    });

    return groupedList;
  } catch (e) {
    console.error(e);
    return data;
  }
};
